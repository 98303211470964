import { cn } from "../lib/utils";
import { useState, useRef, useEffect } from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";
import Language from "./Language";
import { Instagram, Facebook, Youtube } from "lucide-react";
import { useParams } from "react-router-dom";
import { text } from "../text/text";

const MobileNavbar = () => {
  const [language, setLanguage] = useState("ro");

  let { lang } = useParams();
  if (!lang) lang = "ro";
  let content = text[language];

  useEffect(() => {
    if (!lang) setLanguage("ro");
    else setLanguage(lang);
  }, [lang]);

  const [isOpen, setIsOpen] = useState(null);
  const ref = useRef(0);

  const handleOpen = () => {
    setIsOpen((prev) => {
      if (prev === null) return true;
      if (prev === false) return true;
      if (prev === true) return false;
    });
  };

  const handleClose = () => {
    if (isOpen === true) setIsOpen(false);
  };

  useOnClickOutside(ref, handleClose);

  return (
    <div className="flex items-center justify-center">
      <Language className={"px-0 justify-center w-10 md:w-12 sm:mx-1"} />
      <div
        onClick={handleOpen}
        className="z-1000 flex flex-col h-20 items-end pr-6 justify-center"
      >
        <span
          className={cn(
            "h-1 w-9 rounded-md bg-white mt-2 transition-all duration-500",
            isOpen === null
              ? ""
              : isOpen === true
              ? "-rotate-45 translate-y-2"
              : "rotate-0"
          )}
        ></span>
        <span
          className={cn(
            "h-1 w-9 rounded-md bg-white mt-2 transition-all duration-500",
            isOpen === null ? "" : isOpen === true ? "opacity-0" : "opacity-100"
          )}
        ></span>
        <span
          className={cn(
            "h-1 w-9 rounded-md bg-white mt-2 transition-all duration-500",
            isOpen === null
              ? ""
              : isOpen === true
              ? "rotate-45 -translate-y-4"
              : "rotate-0"
          )}
        ></span>
      </div>
      <div
        className={cn(
          "z-0 bg-teal-700  absolute top-0 right-0 w-0 h-0 opacity-0 hidden transition-all duration-500",
          isOpen ? "w-full h-screen fixed opacity-1 block" : ""
        )}
      >
        <a className="mx-8 h-auto text-white font-serif" href="/">
          <img className="w-24 mx-8" src="logo.png" alt="logo" />
        </a>
        <ul ref={ref} className="flex flex-col pl-[15%] pt-[25%] leading-loose">
          <li
            onClick={handleOpen}
            className={cn(
              "text-cyan-50 opacity-60 hover:opacity-100 text-6xl font-[universo]"
            )}
          >
            <a href="#origin">{content.nav.origin}</a>
          </li>
          <li
            onClick={handleOpen}
            className={cn(
              "text-cyan-50 opacity-60 hover:opacity-100 text-6xl font-[universo]"
            )}
          >
            <a href="#benefits">{content.nav.benefits}</a>
          </li>
          <li
            onClick={handleOpen}
            className={cn(
              "text-cyan-50 opacity-60 hover:opacity-100 text-6xl font-[universo]"
            )}
          >
            <a href="#products">{content.nav.products}</a>
          </li>
          <li
            onClick={handleOpen}
            className={cn(
              "text-cyan-50 opacity-60 hover:opacity-100 text-6xl font-[universo]"
            )}
          >
            <a href="#contact">{content.nav.contact}</a>
          </li>
        </ul>

        <div className="bottom-[15%] left-1/4 absolute flex sm:mx-8 justify-center items-center">
          <div className="w-8 h-8 md:w-12 md:h-12 2xl:w-20 2xl:h-20 border cursor-pointer rounded-full mx-2 flex items-center justify-center">
          <a href='https://www.instagram.com/selik.md?igsh=cGNrZm1oZWYyanBj'>
            <Instagram
              color="white"
              className="w-4 h-4 md:w-6 md:h-6 xl:w-10 2xl:h-10"
            />
            </a>
          </div>
          <div className="w-8 h-8 md:w-12 md:h-12 2xl:w-20 2xl:h-20 border cursor-pointer rounded-full mx-2 flex items-center justify-center">
            <a href="https://www.facebook.com/selik.md?mibextid=LQQJ4d">
              {" "}
              <Facebook
                color="white"
                className="w-4 h-4 md:h-6 md:w-6 xl:w-10 2xl:h-10"
              />
            </a>
          </div>
          <div className="w-8 h-8 md:w-12 md:h-12 2xl:w-20 2xl:h-20 border cursor-pointer rounded-full mx-2 flex items-center justify-center">
            <a href="https://youtube.com/@travel_radu?si=3VP9y9ppRIj-jfJu">
              <Youtube
                color="white"
                className="w-4 h-4 md:w-6 md:h-6 xl:w-10 2xl:h-10"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
