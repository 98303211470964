import React, { useEffect, useState } from "react";
import { motion, useMotionValue } from "framer-motion";
import { text } from "../text/text";
import { useParams } from "react-router-dom";

const imgs = [
  "mental-health.jpg",
  "energy.jpg",
  "heart.jpg",
  "nuts.jpg",
  "sistem-imunitar.jpg",
  "skin.jpg",
  "cantar.jpg",
];

const ONE_SECOND = 1000;
const AUTO_DELAY = ONE_SECOND * 10;
const DRAG_BUFFER = 50;

const SPRING_OPTIONS = {
  type: "spring",
  mass: 3,
  stiffness: 400,
  damping: 50,
};

const SwipeCarousel = () => {
  const [imgIndex, setImgIndex] = useState(0);

  const dragX = useMotionValue(0);

  useEffect(() => {
    const intervalRef = setInterval(() => {
      const x = dragX.get();

      if (x === 0) {
        setImgIndex((pv) => {
          if (pv === imgs.length - 1) {
            return 0;
          }
          return pv + 1;
        });
      }
    }, AUTO_DELAY);

    return () => clearInterval(intervalRef);
    // eslint-disable-next-line
  }, []);

  const onDragEnd = () => {
    const x = dragX.get();

    if (x <= -DRAG_BUFFER && imgIndex < imgs.length - 1) {
      setImgIndex((pv) => pv + 1);
    } else if (x >= DRAG_BUFFER && imgIndex > 0) {
      setImgIndex((pv) => pv - 1);
    }
  };

  return (
    <div className="relative overflow-y-hidden h-screen bg-[#60c6c4] -py-12">

      <motion.div
        drag="x"
        dragConstraints={{
          left: 0,
          right: 0,
        }}
        style={{
          x: dragX,
        }}
        animate={{
          translateX: `-${imgIndex * 100}%`,
        }}
        transition={SPRING_OPTIONS}
        onDragEnd={onDragEnd}
        className="flex items-center cursor-grab active:cursor-grabbing"
      >
        <Images className={'relative top-0'} imgIndex={imgIndex} />
      </motion.div>

      <Dots imgIndex={imgIndex} setImgIndex={setImgIndex} />

    </div>
  );
};

const Images = ({ imgIndex }) => {
  const [language, setLanguage] = useState("ro");

  let { lang } = useParams();
  if (!lang) lang = "ro";
  let content = text[language];

  useEffect(() => {
    if (!lang) setLanguage("ro");
    else setLanguage(lang);
    
  }, [lang]);

  return (
    <>
      {content.benefits.map((benefit, idx) => {
        return (
          <div key={`key-${idx}`} className="flex flex-col w-screen">
            <motion.div
              
              style={{
                backgroundImage: `url(${imgs[idx]})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              transition={SPRING_OPTIONS}
              className="w-screen h-[50vh] relative top-0"
            />
            <div className="flex flex-col justify-center p-6">
              <h1 className="text-4xl text-white font-[meta-bold]">{benefit.title}</h1>
              <p className="text-sm text-muted-foreground font-[meta-pro]">{benefit.description}</p>
            </div>
          </div>
        );
      })}
    </>
  );
};

const Dots = ({ imgIndex, setImgIndex }) => {
  return (
    <div className="mt-4 flex w-full justify-center gap-2">
      {imgs.map((_, idx) => {
        return (
          <button
            key={idx}
            onClick={() => setImgIndex(idx)}
            className={`h-3 w-3 rounded-full transition-colors ${
              idx === imgIndex ? "bg-neutral-50" : "bg-neutral-500"
            }`}
          />
        );
      })}
    </div>
  );
};

export default SwipeCarousel;
