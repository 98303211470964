const Benefit = ({title, description}) => {
    
    return (
        <div className="h-screen w-4/5 mx-auto text-left flex flex-col justify-evenly items-center">
            <h1 className="text-8xl font-bold text-white">
                {title}
            </h1>
            <p className="text-2xl font-thin text-black font-[meta-pro]">
                {description}
            </p>
        </div>
    )

}

export default Benefit