import { motion } from "framer-motion";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";
import Searchbar from "./Searchbar";
import SearchbarMobile from "./SearchbarMobile";



const Navbar = () => {
  return (
    <motion.div 
    variants={{
      initial: {
        y: -96,
        opacity: 0.5
      },
      final: {
        y: 0,
        opacity: 1
      }
    }}
    initial='initial'
    animate='final'
    transition={{
      duration: 0.5,
      delay: 0.25
    }}
    className=" items-center md:items-start absolute top-[0] w-full z-50 h-20 md:h-40 px-2 sm:px-2 md:px-0 py-2 grid grid-cols-3">
      <a
        className="w-20 md:w-40 xl:w-40 2xl:w-60 md:my-[23px]  mx-8 h-auto flex items-center text-white font-serif"
        href="/"
      >
        <img className="" src="logo.png" alt="logo" />
      </a>
      {window.innerWidth < 768 ? <SearchbarMobile /> : <Searchbar />}
      {window.innerWidth < 768 ? <MobileNavbar /> : <DesktopNavbar />}
    </motion.div>
  );
};

export default Navbar;
