import { Loader, ShieldCheck } from "lucide-react";
import { Button } from "./ui/button";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { text } from "../text/text";

const Contact = () => {
  const [language, setLanguage] = useState("ro");
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(0);

  let { lang } = useParams();
  if (!lang) lang = "ro";
  let content = text[language];

  useEffect(() => {
    if (!lang) setLanguage("ro");
    else setLanguage(lang);
  }, [lang]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const form = document.getElementById("contact-form");
    const formData = new FormData(form);

    const data = Object.fromEntries(formData.entries());

    try {
      await fetch("/reply/contact-solicitare.php", {
        // Înlocuiește cu URL-ul tău
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the content type to application/json
        },
        body: JSON.stringify(data), // Trimite datele ca FormData
      })
        .then((response) => response.json())
        .then((data) => data.status)
        .catch((err) => console.log(err));

      form.reset();
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setIsLoading(false);
    }
  };
  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
    }
  };

  return (
    <section id="contact" className="bg-white">
      <div className="w-4/5 mx-auto h-screen flex flex-col justify-around">
        <h1 className="text-3xl md:text-5xl lg:text-6xl xl:text-8xl 2xl:text-9xl text-[#003E47]">
          {content.contact.h1}
        </h1>
        <form
          onSubmit={handleSubmit}
          ref={formRef}
          id="contact-form"
          className="h-1/2 flex flex-col justify-evenly"
        >
          <label
            className="font-[satoshi,'Manrope'] text-sm font-bold text-[#26CAD3]"
            htmlFor="name"
          >
            {content.contact.field1} <span className="font-[arial]">*</span>
            <br />
          </label>
          <input
            id="name"
            className="w-full text-3xl bg-transparent border-b border-b-cyan-300 focus:outline-none text-emerald-950 font-[meta-pro]"
            name="name"
            type="text"
          />
          <br />
          <label
            className="font-[satoshi,'Manrope'] text-sm font-bold text-[#26CAD3]"
            htmlFor="mail"
          >
            {content.contact.field2} <span className="font-[arial]">*</span>
            <br />
          </label>
          <input
            id="mail"
            className="w-full text-3xl bg-transparent border-b border-b-cyan-300 focus:outline-none text-emerald-950 font-[meta-pro]"
            name="mail"
            type="text"
          />
          <br />
          <label
            className="font-[satoshi,'Manrope'] text-sm font-bold text-[#26CAD3]"
            htmlFor="phone"
          >
            {content.contact.field3} <span className="font-[arial]">*</span>
            <br />
          </label>
          <input
            id="phone"
            className="w-full text-3xl bg-transparent border-b border-b-cyan-300 focus:outline-none text-emerald-950 font-[meta-pro]"
            name="phone"
            type="text"
          />
          <br />
          <label
            className="font-[satoshi,'Manrope'] text-sm font-bold text-[#26CAD3]"
            htmlFor="message"
          >
            {content.contact.field4} <span className="font-[arial]">*</span>
            <br />
          </label>
          <input
            id="message"
            className="w-full text-3xl bg-transparent border-b border-b-cyan-300 focus:outline-none text-emerald-950 font-[meta-pro]"
            name="message"
            type="text"
          />
          <br />
        </form>
        <div className="flex flex-col justify-center items-center md:flex-row md:justify-around">
          <div className="flex justify-between items-center mb-4 md:mb-0">
            <ShieldCheck className="mr-4" color="#26CAD3" size={35} />{" "}
            <p className="font-[satoshi-thin,'Manrope'] text-sm text-muted-foreground w-fit">
              {content.contact.dataProcess}
            </p>
          </div>
          <Button
            onClick={handleButtonClick}
            className={"bg-[#003E47] w-[100px] mt-4 md:mt-0"}
          >
            {isLoading ? (
              <p className="flex items-center">
                <Loader className=" w-4 h-4 animate-spin" />
              </p>
            ) : (
              content.contact.button
            )}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Contact;
