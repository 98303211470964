import { useState, useEffect } from "react";
import { cn } from "../lib/utils";
import { useParams } from "react-router-dom";
import { text } from "../text/text";

const Sidebar = () => {
  const [language, setLanguage] = useState("ro");

  let { lang } = useParams();
  if (!lang) lang = "ro";
  let content = text[language];

  useEffect(() => {
    if (!lang) setLanguage("ro");
    else setLanguage(lang);
    
  }, [lang]);
  const [activeSection, setActiveSection] = useState("");
  const [textColor, setTextColor] = useState("text-green");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const scrollPosition = window.pageYOffset + window.innerHeight;

      sections.forEach((section) => {
        const sectionStart = section.offsetTop + window.innerHeight / 2;

        if (
          scrollPosition >= sectionStart &&
          scrollPosition <=
            section.offsetTop + section.clientHeight + window.innerHeight / 2
        ) {
          const sectionId = section.getAttribute("id");
          setActiveSection(sectionId);
          if (sectionId === "origin" || sectionId === 'products' || sectionId === 'contact') setTextColor("text-emerald-950");
          else if (sectionId === "benefits") setTextColor("text-white");

        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ul className="fixed -left-20 top-1/2 rotate-[270deg] font-[universo-thin,'Manrope'] font-bold flex flex-row-reverse">
      <li
        className={cn(
          "ml-4 text-xs opacity-35 hover:opacity-100 transition-all",
          activeSection === "hero" || activeSection === "" || activeSection === "footer"
            ? "opacity-0"
            : activeSection === "origin"
            ? "opacity-100"
            : "",
          textColor
        )}
      >
        <a href="#origin">{content.nav.origin}</a>
      </li>
      <li
        className={cn(
          "ml-4 text-xs opacity-35 hover:opacity-100 transition-all",
          activeSection === "hero" || activeSection === "" || activeSection === "footer"
            ? "opacity-0"
            : activeSection === "benefits"
            ? "opacity-100"
            : "",
          textColor
        )}
      >
        <a href="#benefits">{content.nav.benefits}</a>
      </li>
      <li
        className={cn(
          "ml-4 text-xs opacity-35 hover:opacity-100 transition-all",
          activeSection === "hero" || activeSection === "" || activeSection === "footer"
            ? "opacity-0"
            : activeSection === "products"
            ? "opacity-100"
            : "",
          textColor
        )}
      >
        <a href="#products">{content.nav.products}</a>
      </li>
      <li
        className={cn(
          "ml-4 text-xs opacity-35 hover:opacity-100 transition-all",
          activeSection === "hero" || activeSection === "" || activeSection === "footer"
            ? "opacity-0"
            : activeSection === "contact"
            ? "opacity-100"
            : "",
          textColor
        )}
      >
        <a href="#contact">{content.nav.contact}</a>
      </li>
    </ul>
  );
};

export default Sidebar;
