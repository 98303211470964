import Reveal from "./Reveal";
import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {text} from '../text/text';

const Origin = () => {
  const [language, setLanguage] = useState("ro");

  let { lang } = useParams();
  if (!lang) lang = "ro";
  let content = text[language];

  useEffect(() => {
    if (!lang) setLanguage("ro");
    else setLanguage(lang);
    
  }, [lang]);
  return (
    <section
      id="origin"
      className="grid grid-cols-[1fr] lg:grid-cols-[100px_1fr_0_.8fr] h-fit my-[20%]"
      
    >
      <div className=""></div>

      <div className="">
        <Reveal>
          <img
            className=""
            src="amestec_energie.png"
            alt="foto produs"
          />
        </Reveal>
      </div>

      <div className=""></div>

      <div className="flex flex-col justify-center">
          <h1 className="font-thin text-3xl md:text-6xl lg:text-8xl text-center text-teal-500">
            <Reveal>
            <span className="font-bold">
              {content.origin.title} <br />
            </span>{" "}
            </Reveal>
            <Reveal>
            {content.origin.subtitle}
            </Reveal>
          </h1>
            <Reveal>
          <p className="text-xs lg:text-xl text-cyan-500 w-3/5 mx-auto my-7 text-center">
            {content.origin.description}
          </p>
          </Reveal>
        
      </div>
    </section>
  );
};

export default Origin;
