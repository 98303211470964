import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Origin from "../components/Origin";
import Benefits from "../components/Benefits";
import Products from "../components/Products";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { text } from "../text/text";
import { cn } from "../lib/utils";

const Page = () => {
  const [language, setLanguage] = useState("ro");

  let { lang } = useParams();
  if (!lang) lang = "ro";
  let content = text[language];

  useEffect(() => {
    if (!lang) setLanguage("ro");
    else setLanguage(lang);
  }, [lang]);

  return (
    <div className={cn("bg-cyan-50 overflow-hidden font-bold font-['Manrope']")}>
      <Helmet>
        <title>{content.tags.title}</title>
        <meta
          name="description"
          content={content.tags.description1}
        />
        <link rel="canonical" href={`${content.tags.url}`} />

        <meta name="author" content="Ioneli Development SRL" />

        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content={`${content.tags.title2}`}
        />
        <meta
          property="og:description"
          content={`${content.tags.description2}`}
        />
        <meta property="og:image" content="logo.png" />
        <meta property="og:url" content={`${content.tags.url}`} />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-9J655207BR"
        ></script>
      </Helmet>
      <Navbar />
      <Hero />
      <Origin />
      <Benefits />
      <Products />
      <Contact />
      <Footer />
    </div>
  );
};

export default Page;
