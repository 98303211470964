import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useParams } from "react-router-dom";
import { ChevronDown } from "lucide-react";

const Language = ({ className }) => {
  const [language, setLanguage] = useState("ro");

  let { lang } = useParams();
  if (!lang) lang = "ro";

  useEffect(() => {
    if (!lang) setLanguage("ro");
    else setLanguage(lang);
  }, [lang]);

  return (
    <div className="relative top-0 flex items-center  bg-transparent px-3 ">
      <FlyoutLink FlyoutContent={PricingContent}>
        <div className="flex cursor-pointer">
          {language.toUpperCase()} <ChevronDown />
        </div>
      </FlyoutLink>
    </div>
  );
};

export default Language;

const FlyoutLink = ({ children, href, FlyoutContent }) => {
  const [open, setOpen] = useState(false);

  const showFlyout = FlyoutContent && open;

  return (
    <div
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className="relative w-fit h-fit"
    >
      <a href={href} className="relative text-white">
        {children}
        <span
          style={{
            transform: showFlyout ? "scaleX(1)" : "scaleX(0)",
          }}
          className="absolute -bottom-2 -left-2 -right-2 h-1 origin-left scale-x-0 rounded-full bg-indigo-300 transition-transform duration-300 ease-out"
        />
      </a>
      <AnimatePresence>
        {showFlyout && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            style={{ translateX: "-50%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="absolute left-1/2 top-12 bg-white text-black rounded-lg"
          >
            <div className="absolute -top-6 left-0 right-0 h-6 bg-transparent" />
            <div className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white" />
            <FlyoutContent />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const PricingContent = () => {
  return (
    <div className="w-[65px] bg-white mx-auto shadow-xl rounded-lg">
      <div className="mt-3">
        <Link to="/ro" className="block text-sm text-center hover:underline">
          RO
        </Link>
        <Link to="/ru" className="block text-sm text-center hover:underline">
          RU
        </Link>
        <Link to="/en" className="block text-sm text-center hover:underline">
          EN
        </Link>

      </div>
    </div>
  );
};
