import Product from "./Product";
import { Carousel } from "./ui/apple-cards-carousel";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {text} from '../text/text'

const Products = () => {
  const [language, setLanguage] = useState("ro");

  let { lang } = useParams();
  if (!lang) lang = "ro";
  let content = text[language];

  useEffect(() => {
    if (!lang) setLanguage("ro");
    else setLanguage(lang);
    
  }, [lang]);
  const nuts = content.products.PRODUCTS.nuts.map((product) => (
    <Product
      text={content}
      title={product.title}
      content={product.content}
      imgSrc={product.imgSrc}
      key={product.key}
      weight={product.weight}
      protein={product.protein}
      carbs={product.carbs}
      fats={product.fats}
      salt={product.salt}
      fatAcids={product.fatAcids}
      glucide={product.glucide}
      calories={product.calories}
      origin={product.origin}
      ingredients={product.ingredients}
    />
  ));
  const fruits = content.products.PRODUCTS.fruits.map((product) => (
    <Product
      text={content}
      title={product.title}
      content={product.content}
      imgSrc={product.imgSrc}
      key={product.key}
      weight={product.weight}
      protein={product.protein}
      carbs={product.carbs}
      fats={product.fats}
      salt={product.salt}
      fatAcids={product.fatAcids}
      glucide={product.glucide}
      calories={product.calories}
      origin={product.origin}
      ingredients={product.ingredients}
    />
  ));
  const seeds = content.products.PRODUCTS.seeds.map((product) => (
    <Product
      text={content}
      title={product.title}
      content={product.content}
      imgSrc={product.imgSrc}
      key={product.key}
      weight={product.weight}
      protein={product.protein}
      carbs={product.carbs}
      fats={product.fats}
      salt={product.salt}
      fatAcids={product.fatAcids}
      glucide={product.glucide}
      calories={product.calories}
      origin={product.origin}
      ingredients={product.ingredients}
    />
  ));
  const mix = content.products.PRODUCTS.mix.map((product) => (
    <Product
      text={content}
      title={product.title}
      content={product.content}
      imgSrc={product.imgSrc}
      key={product.key}
      weight={product.weight}
      protein={product.protein}
      carbs={product.carbs}
      fats={product.fats}
      salt={product.salt}
      fatAcids={product.fatAcids}
      glucide={product.glucide}
      calories={product.calories}
      origin={product.origin}
      ingredients={product.ingredients}
    />
  ));


  return (
    <section id="products" className="h-fit bg-cyan-50">
      {window.innerWidth < 768 ? (
        <p className="relative left-[15px] top-[70px] text-xl z-10 text-white font-['Manrope']">
          {content.products.sectionTitle}
        </p>
      ) : null}
      <img
        className="mt-[2rem] overflow-hidden w-full h-auto"
        src="Fundal.png"
        alt="fundal"
      />
      <div className="text-center mt-10 md:my-20 w-[90vw] h-fit mx-auto flex flex-wrap justify-evenly">
        <h1 className="mb-6 text-teal-500 w-full mx-6 text-4xl">{content.products.category1}</h1>
        <Carousel items={nuts} />
      </div>
      <div className="text-center mt-10 w-[90vw] h-fit mx-auto flex flex-wrap justify-evenly">
        <h1 className="mb-6 text-teal-500 w-full mx-6 text-4xl">{content.products.category2}</h1>
        <Carousel items={fruits} />
      </div>
      <div className="text-center mt-10 w-[90vw] h-fit mx-auto flex flex-wrap justify-evenly">
        <h1 className="mb-6 text-teal-500 w-full mx-6 text-4xl">{content.products.category3}</h1>
        <Carousel items={seeds} />
      </div>
      <div className="text-center mt-10 w-[90vw] h-fit mx-auto flex flex-wrap justify-evenly">
        <h1 className="mb-6 text-teal-500 w-full mx-6 text-4xl">{content.products.category4}</h1>
        <Carousel items={mix} />
      </div>
    </section>
  );
};

export default Products;

