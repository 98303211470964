import Language from "./Language";
import { Facebook, Instagram, Youtube } from "lucide-react";
import { useState } from "react";
import Sidebar from "./Sidebar";

const DesktopNavbar = () => {
  const [isHovered, setIsHovered] = useState(null);

  const handleMouseEnter = (item) => {
    setIsHovered(item);
  };
  const handleMouseLeave = () => {
    setIsHovered(null);
  };
  return (
    <div className="my-[56px]">
    
    <div className="flex sm:mx-8 justify-end">
      <Language className={"px-0 justify-center w-10 md:w-12 sm:mx-1"} />
      <div className="w-8 h-8 md:w-12 md:h-12 2xl:w-20 2xl:h-20 border cursor-pointer rounded-full mx-2 flex items-center justify-center">
          <a href='https://www.instagram.com/selik.md?igsh=cGNrZm1oZWYyanBj'>
        <Instagram
          className="w-4 h-4 md:w-6 md:h-6 xl:w-10 2xl:h-10"
          onMouseEnter={() => handleMouseEnter("instagram")}
          onMouseLeave={handleMouseLeave}
          color={isHovered === "instagram" ? "cyan" : "white"}
        />
        </a>
      </div>
      <div className="w-8 h-8 md:w-12 md:h-12 2xl:w-20 2xl:h-20 border cursor-pointer rounded-full mx-2 flex items-center justify-center">
      <a href="https://www.facebook.com/selik.md?mibextid=LQQJ4d">
        <Facebook
          className="w-4 h-4 md:h-6 md:w-6 xl:w-10 2xl:h-10"
          onMouseEnter={() => handleMouseEnter("facebook")}
          onMouseLeave={handleMouseLeave}
          color={isHovered === "facebook" ? "cyan" : "white"}
        />
        </a>
      </div>
      <div className="w-8 h-8 md:w-12 md:h-12 2xl:w-20 2xl:h-20 border cursor-pointer rounded-full mx-2 flex items-center justify-center">
      <a href="https://youtube.com/@travel_radu?si=3VP9y9ppRIj-jfJu">
        <Youtube
          className="w-4 h-4 md:w-6 md:h-6 xl:w-10 2xl:h-10"
          onMouseEnter={() => handleMouseEnter("twitter")}
          onMouseLeave={handleMouseLeave}
          color={isHovered === "twitter" ? "cyan" : "white"}
        />
        </a>
      </div>
    </div>
    <Sidebar />
    </div>
  );
};

export default DesktopNavbar;
