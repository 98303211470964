import { motion, useScroll, useTransform } from "framer-motion";
import { useRef, useState, useEffect } from "react";
import BenefitsDesktop from "./BenefitsDesktop";
import BenefitsMobile from "./BenefitsMobile";
import { useParams } from "react-router-dom";
import { text } from "../text/text";

const Benefits = () => {
  const [language, setLanguage] = useState("ro");

  let { lang } = useParams();
  if (!lang) lang = "ro";
  let content = text[language];

  useEffect(() => {
    if (!lang) setLanguage("ro");
    else setLanguage(lang);
    
  }, [lang]);

  const ref = useRef();

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "2.5 1"],
  });

  const translateX = useTransform(scrollYProgress, [0, 1], ['0%', '85%']),
  translateX1 = useTransform(scrollYProgress, [0, 1], ['0%', '-85%'])




  return (
    <div>
      <div className="pb-[20%]">
        <motion.h1 style={{translateX: translateX, transition: 'all .75s ease-out'}}   className="text-4xl  sm:text-5xl md:text-6xl lg:text-8xl xl:text-8xl 2xl:text-9xl  font-thin relative right-[76%]">
          {content.benefits_animation.firstLine}
        </motion.h1>
        <motion.h1 ref={ref} style={{translateX: translateX1, transition: 'all .75s ease-out'}}  className="text-4xl  sm:text-5xl md:text-7xl lg:text-8xl xl:text-8xl 2xl:text-9xl text-teal-500 font-bold relative left-[110%]">
          {content.benefits_animation.secondLine}
        </motion.h1>
      </div>
      <section id="benefits" className="w-full">
        {window.innerWidth >= 1024 ? <BenefitsDesktop /> : <BenefitsMobile />}
      </section>
    </div>
  );
};

export default Benefits;
