import ReactCardFlip from "react-card-flip";
import { useState, useRef } from "react";
import useOnClickOutside from '../hooks/useOnClickOutside'

const Product = ({ title, imgSrc, id, weight, text, protein, carbs, fats, salt, glucide, fatAcids, calories, origin, ingredients  }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const ref = useRef(0);

  const flipCard = () => {
    setIsFlipped((prev) => !prev);
  };

  const flipToMain = () => {
    setIsFlipped(false);
  }

  useOnClickOutside(ref, flipToMain);

  return (
    <ReactCardFlip
      containerClassName="m-3 min-w-[300px] w-[50vw] md:w-[25vw] xl:w-[20vw] h-full"
      key={id}
      flipDirection="horizontal"
      isFlipped={isFlipped}
    >
      <div onClick={flipCard} onMouseEnter={flipCard} className="bg-white shadow rounded-xl w-full h-full flex flex-col items-start justify-between">
        <div className="flex flex-col">
        <p className="text-2xl md:text-4xl text-teal-500 font-[universo,'Manrope'] ml-4 mt-4">{title}</p>
        <p className="text-xs md:text-base text-muted-foreground font-[universo-thin,'Manrope'] ml-4">{text.products.weight}: {weight}g</p>
        </div>
        
        <img
          src={imgSrc}
          alt="imagine produs"
          className="w-full h-auto mx-auto p-4"
        />
      </div>
      <div
        ref={ref}
        className="w-full h-full rounded-xl p-4 bg-teal-500 text-white flex flex-col gap-y-4 text" 
        onMouseLeave={flipToMain}
        onClick={flipCard}
      >
        <h1 className=" text-2xl border-b border-b-white">{text.products.header}</h1>
        <h3 className="flex justify-between border-b-4 border-b-white"><span>{text.products.mass}</span><span>{weight} g</span></h3>
        <h3 className="flex justify-between border-b-4 border-b-white"><span>{text.products.calories}</span><span>{calories}</span></h3>
        <div className="flex flex-col text-xs border-b-4 border-b-white">
          { fats !== null ?
          <p className="flex justify-between"><span>{text.products.fats}</span><span>{fats} g</span></p> : null }
          { protein !== null ?
          <p className="flex justify-between"><span>{text.products.protein}</span><span>{protein} g</span></p> : null }
          { glucide !== null ?
          <p className="flex justify-between"><span>{text.products.glucide}</span><span>{glucide} g</span></p> : null }
          { carbs !== null ?
          <p className="flex justify-between"><span>{text.products.carbs}</span><span>{carbs} g</span></p> : null }
          { fatAcids !== null ?
          <p className="flex justify-between"><span>{text.products.fatAcids}</span><span>{fatAcids} g</span></p> : null }
          { salt !== null ?
          <p className="flex justify-between"><span>{text.products.salt}</span><span>{salt} g</span></p> : null }
        </div>
        <div className="flex flex-col text-xs border-b-4 border-b-white">
        {ingredients.map((ingredient, i) => (
            <p key={`${ingredient.name}-${i}`} className="flex justify-between"><span>{ingredient.name}</span><span>{ingredient.value}%</span></p>
          ))}
        </div>
        <p className="text-xs text-muted-foreground">{text.products.keepCondition}</p>
        <p className="flex justify-between text-xs"><span>{text.products.origin}</span><span>{origin}</span></p>


      </div>
    </ReactCardFlip>
  );
};

export default Product;
