const Hero = () => {

  return (
    <section id="hero" className=" px-3 mr-4 w-full  md:w-[calc(100%-16px)] relative top-[-96px] h-[calc(100vh+80px)] overflow-hidden flex justify-center md:justify-normal z-0">
       <video src="video3.mp4" type='video/mp4' className="h-full rounded-b-[32px] object-cover w-full z-0" autoPlay loop muted playsInline />
    </section>
  );
};

export default Hero;
