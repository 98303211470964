import SwipeCarousel from "./SwipeCarousel";
const BenefitsMobile = () => {
  return (
    <div className="w-full">
      <p className="relative left-[15px] top-[50px] text-xl z-10 text-white font-['Manrope']">
        BENEFITS
      </p>
      <SwipeCarousel />
    </div>
  );
};

export default BenefitsMobile;
