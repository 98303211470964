const nutritionFacts = [
  {
    imgSrc: "alune_de_padure.png",
    weight: 70,
    calories: 636,
    protein: "10,7",
    fats: "66,5",
    fatAcids: "5",
    glucide: "9,6",
    salt: 0,
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "amestec_energie.png",
    weight: 80,
    calories: 447,
    protein: "8,1",
    fats: "24,4",
    fatAcids: "4",
    glucide: "52,2",
    salt: "0,1",
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "Ananas.png",
    weight: 70,
    calories: 268,
    protein: "2,16",
    fats: "1,5",
    fatAcids: null,
    glucide: "63,4",
    salt: null,
    carbs: null,
    origin: null,
    lipide: null,
  },
  {
    imgSrc: "arahide_decojite.png",
    weight: 150,
    calories: "552",
    protein: "25,3",
    fats: "45",
    fatAcids: null,
    glucide: "12,1",
    salt: null,
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "arahide_sarate1.png",
    weight: 65,
    calories: "620",
    protein: "25",
    fats: "50,3",
    fatAcids: null,
    glucide: "13,5",
    salt: null,
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "arahide_sarate2.png",
    weight: 120,
    calories: "620",
    protein: "25",
    fats: "50,3",
    fatAcids: null,
    glucide: "13,5",
    salt: null,
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "Chia.png",
    weight: 100,
    calories: "486",
    protein: "18,8",
    fats: null,
    lipide: "30,3",
    fatAcids: "4,3",
    glucide: "33,2",
    salt: "0",
    carbs: null,
  },
  {
    imgSrc: "Cocos.png",
    weight: 100,
    calories: "630",
    protein: "6,94",
    fats: "64,7",
    fatAcids: "57,3",
    glucide: "15,3",
    salt: "0,1",
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "Curmale.png",
    weight: 120,
    calories: "305",
    protein: "2,2",
    fats: "0,5",
    fatAcids: "0,03",
    glucide: "71,9",
    salt: "0",
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "fistic_100.png",
    weight: 100,
    calories: "566",
    protein: "20,1",
    fats: "48",
    fatAcids: null,
    glucide: null,
    salt: null,
    carbs: "14",
    lipide: null,
  },
  {
    imgSrc: "seminte_in.png",
    weight: 200,
    calories: "534",
    protein: "18,3",
    fats: "42,4",
    fatAcids: "2,8",
    glucide: null,
    salt: "0",
    carbs: "17",
    lipide: null,
  },
  {
    imgSrc: "Migdale.png",
    weight: 70,
    calories: "596",
    protein: "17,9",
    fats: "51,3",
    fatAcids: null,
    glucide: "16,1",
    salt: null,
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "fistic_60.png",
    weight: 60,
    calories: "566",
    protein: "20,1",
    fats: "48",
    fatAcids: null,
    glucide: null,
    salt: null,
    carbs: "14",
    lipide: null,
  },
  {
    imgSrc: "4seminte.png",
    weight: 150,
    calories: "495",
    protein: "23",
    fats: "32,2",
    fatAcids: "3",
    glucide: "22,4",
    salt: 0,
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "Papaya.png",
    weight: 100,
    calories: "350",
    protein: "1",
    fats: "88",
    fatAcids: "0",
    glucide: "71",
    salt: "0,24",
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "mix_legume.png",
    weight: 50,
    calories: "528",
    protein: "1,2",
    fats: "31",
    fatAcids: "3,9",
    glucide: "14,5",
    salt: "0,7",
    carbs: "56,8",
    lipide: null,
  },
  {
    imgSrc: "stafide_sultana.png",
    weight: "150",
    calories: "262",
    protein: "1,8",
    fats: "0,2",
    fatAcids: "0,1",
    glucide: "66",
    salt: "0,011",
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "Tropicale.png",
    weight: "90",
    calories: "345",
    protein: "2,3",
    fats: "6,5",
    fatAcids: "2",
    glucide: "7,5",
    salt: "0,1",
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "seminte_susan.png",
    weight: "100",
    calories: "565",
    protein: "19,4",
    fats: "48,7",
    fatAcids: "23",
    glucide: "12,2",
    salt: "0",
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "fulgi_migdale.png",
    weight: "50",
    calories: "596",
    protein: "17,9",
    fats: "51,3",
    fatAcids: "4,9",
    glucide: "16,2",
    salt: "0,02",
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "ghimbir.png",
    weight: "100",
    calories: "294",
    protein: "0,88",
    fats: 0,
    fatAcids: null,
    glucide: "53,1",
    salt: null,
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "Caju.png",
    weight: "70",
    calories: "572",
    protein: "17,5",
    fats: "42,4",
    fatAcids: "7,7",
    glucide: "30,5",
    salt: "0",
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "Ceai.png",
    weight: "90",
    calories: "447",
    protein: "8,1",
    fats: "24,4",
    fatAcids: "0,02",
    glucide: "52,2",
    salt: "0,01",
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "mango.png",
    weight: "100",
    calories: "340",
    protein: "5",
    fats: "0,5",
    fatAcids: "0",
    glucide: "64",
    salt: "0,24",
    carbs: "79",
    lipide: null,
  },
  {
    imgSrc: "quinoa.png",
    weight: "150",
    calories: "368",
    protein: "14",
    fats: null,
    fatAcids: null,
    glucide: "64",
    salt: null,
    carbs: null,
    lipide: "6",
  },
  {
    imgSrc: "seminte_fs.png",
    weight: "200",
    calories: "601",
    protein: "24",
    fats: "47,3",
    fatAcids: "3,3",
    glucide: "16,1",
    salt: "0",
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "stafide_gold.png",
    weight: "150",
    calories: "262",
    protein: "1,8",
    fats: "0,2",
    fatAcids: "0,1",
    glucide: "66",
    salt: "0,01",
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "Rachitele.png",
    weight: "100",
    calories: "306",
    protein: "0,5",
    fats: "0,2",
    fatAcids: "0,1",
    glucide: "3,7",
    salt: "0",
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "Caise.png",
    weight: "120",
    calories: "291",
    protein: "5",
    fats: "0,5",
    fatAcids: "0,02",
    glucide: "63,5",
    salt: "0,07",
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "Smochine.png",
    weight: "120",
    calories: "278",
    protein: "2,5",
    fats: "0,8",
    fatAcids: null,
    glucide: "67,7",
    salt: null,
    carbs: null,
    lipide: null,
  },
  {
    imgSrc: "Mac.png",
    weight: "100",
    calories: "522",
    protein: "17,3",
    fats: "46,2",
    fatAcids: "0",
    glucide: "9,26",
    salt: "0",
    carbs: null,
    lipide: null,
  },
];

export const text = {
  ro: {
    tags: {
      title: "Selik - gamă largă de alune, nuci și fructe uscate",
      title2: "Vezi produsele Selik pe această pagină",
      description1:
        "Produse Selik, gama de produse pe pagina noastră. Alune, arahine, fistic, caju, și fructe uscate. Descoperă produsele selik",
      description2:
        "Am găsit aici alune, arahide, caju, fructe uscate și alte produse selik",
      url: "https://selik.md",
    },
    search: {
      searchPlaceholder: "Caută produsul dorit sau conținutul",
      noresult: "Nici un rezultat potrivit",
    },
    hero: {
      firstLine: "Selecția ta",
      secondLine: "de nuci și fructe uscate",
    },
    contact: {
      h1: "contactează-ne",
      field1: "NUME",
      field2: "EMAIL",
      field3: "TELEFON",
      field4: "MESAJ",
      dataProcess: "Datele sunt procesate într-un mediu securizat",
      button: "Trimite",
    },
    footer: {
      col1: "Date de contact",
      col2: "Limbă",
      address: "Strada Muncești 121, Chișinău, Moldova",
      rights: "Toate drepturile rezervate - ",
      author: "Dezvoltat și administrat de",
    },
    nav: {
      origin: "ORIGIN",
      benefits: "BENEFICII",
      products: "PRODUSE",
      contact: "CONTACT",
    },
    origin: {
      title: "Amestecuri delicioase",
      subtitle: " energie și dispoziție",
      description:
        "Poți alege produsele separate sau gama noastră de amestecurile care sunt o sursă de energie pentru sport sau o gustare pentru film",
    },
    products: {
      category1: "Alune",
      category2: "Fructe uscate",
      category3: "Semințe",
      category4: "Mix",
      sectionTitle: "PRODUSE",
      header: "Date nutriționale",
      calories: "Calorii",
      mass: "Masă netă",
      weight: "Cantitate",
      fats: "Grăsimi",
      protein: "Proteine",
      glucide: "Glucide",
      carbs: "Carbohidrați",
      fatAcids: "Acizi grași saturați",
      salt: "Sare",
      keepCondition:
        "A se păstra la loc uscat și răcoros la temperatura de la +3°C până la +18°C și umiditatea aerului 70%",
      origin: "Țara de origine",
      PRODUCTS: {
        nuts: [
          {
            title: "Alune de padure",
            ...nutritionFacts[0],
            origin: "Turcia",
            ingredients: [
              {
                name: "Alune de pădure",
                value: "100",
              },
            ],
            key: 1,
          },
          {
            title: "Arahide decojite",
            ...nutritionFacts[3],
            ingredients: [
              {
                name: "arahide",
                value: 100,
              },
            ],
            origin: "India",
            key: 2,
          },
          {
            title: "Arahide sarate",
            ...nutritionFacts[4],
            ingredients: [
              {
                name: "arahide",
                value: 100,
              },
              {
                name: "sare",
                value: "",
              },
            ],
            origin: "India",
            key: 4,
          },
          {
            title: "Fistic",
            ...nutritionFacts[9],
            ingredients: [
              {
                name: "fistic",
                value: 100,
              },
            ],
            origin: "SUA",
            key: 5,
          },
          {
            title: "Migdale",
            ...nutritionFacts[11],
            ingredients: [
              {
                name: "migdale",
                value: 100,
              },
            ],
            origin: "SUA",
            key: 6,
          },
          {
            title: "Fistic",
            ...nutritionFacts[12],
            ingredients: [
              {
                name: "fistic",
                value: 100,
              },
            ],
            origin: "SUA",
            key: 7,
          },
          {
            title: "Nuci Caju",
            ...nutritionFacts[21],
            ingredients: [
              {
                name: "Nuci Caju",
                value: 100,
              },
            ],
            origin: "Vietnam",
            key: 8,
          },
          {
            title: "Fulgi de migdale",
            ...nutritionFacts[19],
            ingredients: [
              {
                name: "Fulgi de migdale",
                value: "100",
              },
            ],
            origin: "SUA",
            key: 9,
          },
        ],
        fruits: [
          {
            title: "Mango",
            ...nutritionFacts[23],
            ingredients: [
              {
                name: "Mango",
                value: "",
              },
              {
                name: "Trestie de zahăr",
                value: "",
              },
              {
                name: "Acid citric",
                value: "",
              },
              {
                name: "Clorura de calciu",
                value: "",
              },
            ],
          },
          {
            title: "Ananas",
            ...nutritionFacts[2],
            ingredients: [
              {
                name: "Ananas uscat taiat",
                value: 100,
              },
            ],
            origin: "Thailanda",
            key: 10,
          },
          {
            title: "Curmale",
            ...nutritionFacts[8],
            ingredients: [
              {
                name: "curmale",
                value: 100,
              },
            ],
            origin: "Tunisia",
            key: 11,
          },
          {
            title: "Papaya",
            ...nutritionFacts[14],
            ingredients: [
              {
                name: "Papaya",
                value: "",
              },
              {
                name: "trestie de zahar",
                value: "",
              },
              {
                name: "acid citric",
                value: "",
              },
              {
                name: "clorura de calciu",
                value: "",
              },
            ],
            origin: "Thailanda",
            key: 12,
          },
          {
            title: "Stafide sultana",
            ...nutritionFacts[16],
            ingredients: [
              {
                name: "Stafide uscate Sultana",
                value: "100",
              },
            ],
            origin: "Iran",
            key: 13,
          },
          {
            title: "Fulgi de cocos",
            ...nutritionFacts[7],
            ingredients: [
              {
                name: "Cocos",
                value: 100,
              },
            ],
            origin: "Sri Lanka",
            key: 14,
          },
          {
            title: "Ghimbir",
            ...nutritionFacts[20],
            ingredients: [
              {
                name: "Ghimbir",
                value: "",
              },
              {
                name: "Trestie de zahăr",
                value: "",
              },
              {
                name: "Acid Citric",
                value: "",
              },
              {
                name: "Glazură",
                value: "",
              },
            ],
            origin: "Thailanda",
            key: 15,
          },
          {
            title: "Stafide Gold",
            ...nutritionFacts[26],
            ingredients: [
              {
                name: "Stafide uscate gold",
                value: "",
              },
            ],
            origin: "Iran",
            key: 16,
          },
          {
            title: "Răchițele",
            ...nutritionFacts[27],
            ingredients: [
              {
                name: "Răchițele uscate",
                value: "",
              },
              {
                name: "Trestie de zahăr",
                value: "",
              },
            ],
            origin: "SUA",
            key: 200,
          },
          {
            title: "Caise uscate",
            ...nutritionFacts[28],
            ingredients: [
              {
                name: "Caise uscate",
                value: "100",
              },
            ],
            origin: "Turcia",
            key: 204,
          },
          {
            title: "Smochine",
            ...nutritionFacts[29],
            ingredients: [
              {
                name: "Smochine uscate",
                value: 100,
              },
            ],
            origin: "Turcia",
            key: 300,
          },
        ],
        seeds: [
          {
            title: "Seminte de Chia",
            ...nutritionFacts[6],
            ingredients: [
              {
                name: "Seminte de chia",
                value: 100,
              },
            ],
            origin: "Argentina",
            key: 17,
          },
          {
            title: "Quinoa",
            ...nutritionFacts[24],
            ingredients: [
              {
                name: "Seminte de quinoa albe, negre, roșii",
                value: "",
              },
            ],
            origin: "Peru",
            key: 18,
          },
          {
            title: "Seminte de in",
            ...nutritionFacts[10],
            ingredients: [
              {
                name: "seminte de in",
                value: 100,
              },
            ],
            origin: "Ucraina",
            key: 20,
          },
          {
            title: "Seminte de susan",
            ...nutritionFacts[18],
            ingredients: [
              {
                name: "Semințe de susan",
                value: "100",
              },
            ],
            origin: "India",
            key: 21,
          },
          {
            title: "Mac",
            ...nutritionFacts[30],
            ingredients: [
              {
                name: "Mac",
                value: 100,
              },
            ],
            origin: "Turcia",
            key: 309,
          },
        ],
        mix: [
          {
            title: "Amestec energie",
            ...nutritionFacts[1],
            origin: "Thailanda, SUA, Iran, Turcia, Vietnam",
            ingredients: [
              {
                name: "Ananas",
                value: 20,
              },
              {
                name: "papaya",
                value: 10,
              },
              {
                name: "caise uscate",
                value: 10,
              },
              {
                name: "migdale",
                value: 10,
              },
              {
                name: "răchițele",
                value: 20,
              },
              {
                name: "stafide",
                value: 20,
              },
              {
                name: "nuci caju",
                value: 10,
              },
            ],
            key: 22,
          },
          {
            title: "Amestec la ceai",
            ...nutritionFacts[22],
            ingredients: [
              {
                name: "Alune de pădure",
                value: "10",
              },
              {
                name: "Migdale",
                value: "15",
              },
              {
                name: "Stafide",
                value: "25",
              },
              {
                name: "Ananas",
                value: "25",
              },
              {
                name: "Papaya",
                value: "25",
              },
            ],
            origin: "Turcia, SUA, Chile, Thailanda",
            key: 23,
          },
          {
            title: "Fructe tropicale",
            ...nutritionFacts[17],
            ingredients: [
              {
                name: "Ananas",
                value: "20",
              },
              {
                name: "Papaya",
                value: "20",
              },
              {
                name: "Răchițele",
                value: "20",
              },
              {
                name: "Cocos cub",
                value: "20",
              },
              {
                name: "Stafide",
                value: "20",
              },
            ],
            origin: "Thailanda, Iran, SUA, Olanda",
            key: 24,
          },
          {
            title: "4 Seminte",
            ...nutritionFacts[13],
            origin: "India, Ucraina, Moldova",
            ingredients: [
              {
                name: "Miez de floarea soarelui",
                value: "25",
              },
              {
                name: "Seminte de in",
                value: "25",
              },
              {
                name: "Seminte de susan",
                value: 25,
              },
              {
                name: "Seminte de dovleac",
                value: 25,
              },
            ],
            key: 25,
          },

          {
            title: "Mix de legume",
            ...nutritionFacts[15],
            ingredients: [
              {
                name: "Cassava",
                value: "",
              },
              {
                name: "Cartof dulce batat",
                value: "",
              },
              {
                name: "Morcov",
                value: "",
              },
              {
                name: "Sfecla roșie",
                value: "",
              },
              {
                name: "Ulei de măsline",
                value: "",
              },
              {
                name: "Extracte naturale din plante",
                value: "",
              },
            ],
            origin: "Moldova",
            key: 27,
          },
        ],
      },
    },
    benefits_animation: {
      firstLine: "Un strop de sănătate",
      secondLine: "și de energie",
    },
    benefits: [
      {
        key: 1,
        title: "Minte Sănătoasă",
        description:
          "Alunele și fructele uscate conțin acizi grași Omega-3 și Omega-6, care sunt esențiali pentru funcționarea creierului. Consumul regulat poate îmbunătăți memoria și concentrarea, prevenind în același timp deteriorarea cognitivă.",
      },
      {
        key: 2,
        title: "Sursă de energie",
        description:
          "Fiind bogate în carbohidrați și proteine, fructele uscate și alunele oferă un plus de energie rapid, ceea ce le face ideale ca gustări înainte sau după activități fizice.",
      },
      {
        key: 3,
        title: "Sănătatea inimii",
        description:
          'Alunele, în special cele crude, sunt o sursă excelentă de grăsimi nesaturate și fibre, care ajută la reducerea nivelului de colesterol "rău" (LDL) și la menținerea sănătății cardiovasculare.',
      },
      {
        key: 4,
        title: "Îmbunătățirea digestiei",
        description:
          "Fructele uscate, precum smochinele și prunele, sunt bogate în fibre, contribuind la o digestie sănătoasă și prevenind constipația. De asemenea, susțin sănătatea microbiotei intestinale.",
      },
      {
        key: 5,
        title: "Întărirea sistemului imunitar",
        description:
          "Alunele și fructele uscate sunt bogate în antioxidanți (vitamina E, seleniu), care ajută la combaterea radicalilor liberi și la întărirea sistemului imunitar.",
      },
      {
        key: 6,
        title: "Piele sănătoasă",
        description:
          "Vitamina E și alți antioxidanți din alune contribuie la sănătatea pielii, protejând-o de îmbătrânirea prematură și de efectele negative ale radiațiilor UV.",
      },
      {
        key: 7,
        title: "Controlul greutății",
        description:
          "Deși au un conținut caloric ridicat, consumate cu moderație, alunele și fructele uscate pot ajuta la controlul greutății datorită aportului de proteine și fibre, care induc senzația de sațietate.",
      },
    ],
  },
  en: {
    tags: {
      title: "Selik - wide range of hazelnuts, nuts, and dried fruits",
      title2: "See Selik products on this page",
      description1:
        "Selik products, the range of products on our page. Hazelnuts, peanuts, pistachios, cashews, and dried fruits. Discover Selik products",
      description2:
        "We found here hazelnuts, peanuts, cashews, dried fruits, and other Selik products",
      url: "https://selik.md/en",
    },
    search: {
      searchPlaceholder: "Search for product",
      noresult: "No results found",
    },
    hero: {
      firstLine: "Your selection",
      secondLine: "of nuts and dried fruits",
    },
    contact: {
      h1: "contact us",
      field1: "NAME",
      field2: "EMAIL",
      field3: "PHONE",
      field4: "MESSAGE",
      dataProcess: "The data is processed in a secure environment",
      button: "Send",
    },
    footer: {
      col1: "Contact details",
      col2: "Language",
      address: "Muncești Street 121, Chișinău, Moldova",
      rights: "All rights reserved - ",
      author: "Developed and managed by",
    },
    nav: {
      origin: "ORIGIN",
      benefits: "BENEFITS",
      products: "PRODUCTS",
      contact: "CONTACT",
    },
    origin: {
      title: "Delicious Mixes",
      subtitle: "energy and mood",
      description:
        "You can choose separate products or our range of mixes that are a source of energy for sports or a snack for a movie.",
    },

    products: {
      category1: "Nuts",
      category2: "Dried fruits",
      category3: "Seeds",
      category4: "Mix",
      sectionTitle: "PRODUCTS",
      header: "Nutritional information",
      calories: "Calories",
      mass: "Net weight",
      weight: "Quantity",
      fats: "Fats",
      protein: "Proteins",
      glucide: "Sugars",
      carbs: "Carbohydrates",
      fatAcids: "Saturated fatty acids",
      salt: "Salt",
      keepCondition:
        "Store in a dry and cool place at a temperature between +3°C and +18°C and 70% air humidity",
      origin: "Country of origin",
      PRODUCTS: {
        nuts: [
          {
            title: "Hazelnuts",
            ...nutritionFacts[0],
            origin: "Turkey",
            ingredients: [
              {
                name: "Hazelnuts",
                value: "100",
              },
            ],
            key: 28,
          },
          {
            title: "Shelled peanuts",
            ...nutritionFacts[3],
            ingredients: [
              {
                name: "Peanuts",
                value: 100,
              },
            ],
            origin: "India",
            key: 29,
          },
          {
            title: "Cashew Nuts",
            ...nutritionFacts[21],
            ingredients: [
              {
                name: "Cashew nuts",
                value: 100,
              },
            ],
            origin: "Vietnam",
            key: 30,
          },
          {
            title: "Salted peanuts",
            ...nutritionFacts[4],
            ingredients: [
              {
                name: "Peanuts",
                value: 100,
              },
              {
                name: "Salt",
                value: "",
              },
            ],
            origin: "India",
            key: 32,
          },
          {
            title: "Pistachios",
            ...nutritionFacts[9],
            ingredients: [
              {
                name: "Pistachios",
                value: 100,
              },
            ],
            origin: "USA",
            key: 33,
          },
          {
            title: "Almonds",
            ...nutritionFacts[11],
            ingredients: [
              {
                name: "Almonds",
                value: 100,
              },
            ],
            origin: "USA",
            key: 34,
          },
          {
            title: "Pistachios",
            ...nutritionFacts[12],
            ingredients: [
              {
                name: "Pistachios",
                value: 100,
              },
            ],
            origin: "USA",
            key: 35,
          },
          {
            title: "Almond flakes",
            ...nutritionFacts[19],
            ingredients: [
              {
                name: "Almond flakes",
                value: "100",
              },
            ],
            origin: "USA",
            key: 36,
          },
        ],
        fruits: [
          {
            title: "Mango",
            ...nutritionFacts[23],
            ingredients: [
              {
                name: "Mango",
                value: "",
              },
              {
                name: "Sugarcane",
                value: "",
              },
              {
                name: "Citric acid",
                value: "",
              },
              {
                name: "Calcium chloride",
                value: "",
              },
            ],
          },
          {
            title: "Pineapple",
            ...nutritionFacts[2],
            ingredients: [
              {
                name: "Dried pineapple",
                value: 100,
              },
            ],
            origin: "Thailand",
            key: 37,
          },
          {
            title: "Dates",
            ...nutritionFacts[8],
            ingredients: [
              {
                name: "Dates",
                value: 100,
              },
            ],
            origin: "Tunisia",
            key: 38,
          },
          {
            title: "Papaya",
            ...nutritionFacts[14],
            ingredients: [
              {
                name: "Papaya",
                value: "",
              },
              {
                name: "Sugar cane",
                value: "",
              },
              {
                name: "Citric acid",
                value: "",
              },
              {
                name: "Calcium chloride",
                value: "",
              },
            ],
            origin: "Thailand",
            key: 39,
          },
          {
            title: "Sultana raisins",
            ...nutritionFacts[16],
            ingredients: [
              {
                name: "Dried Sultana raisins",
                value: "100",
              },
            ],
            origin: "Iran",
            key: 40,
          },
          {
            title: "Coconut flakes",
            ...nutritionFacts[7],
            ingredients: [
              {
                name: "Coconut",
                value: 100,
              },
            ],
            origin: "Sri Lanka",
            key: 41,
          },
          {
            title: "Ginger",
            ...nutritionFacts[20],
            ingredients: [
              {
                name: "Ginger",
                value: "",
              },
              {
                name: "Sugar cane",
                value: "",
              },
              {
                name: "Citric acid",
                value: "",
              },
              {
                name: "Glaze",
                value: "",
              },
            ],
            origin: "Thailand",
            key: 42,
          },
          {
            title: "Golden Raisins",
            ...nutritionFacts[26],
            ingredients: [
              {
                name: "Dried golden raisins",
                value: "",
              },
            ],
            origin: "Iran",
            key: 43,
          },
          {
            title: "Cranberries",
            ...nutritionFacts[27],
            ingredients: [
              {
                name: "Dried Cranberries",
                value: "",
              },
              {
                name: "Sugar Cane",
                value: "",
              },
            ],
            origin: "USA",
            key: 201,
          },
          {
            title: "Dried Apricots",
            ...nutritionFacts[28],
            ingredients: [
              {
                name: "Dried Apricots",
                value: "100",
              },
            ],
            origin: "Turkey",
            key: 205,
          },
          {
            title: "Figs",
            ...nutritionFacts[29],
            ingredients: [
              {
                name: "Dried figs",
                value: 100,
              },
            ],
            origin: "Turkey",
            key: 311,
          },
        ],
        seeds: [
          {
            title: "Chia seeds",
            ...nutritionFacts[6],
            ingredients: [
              {
                name: "Chia seeds",
                value: 100,
              },
            ],
            origin: "Argentina",
            key: 44,
          },
          {
            title: "Flax seeds",
            ...nutritionFacts[10],
            ingredients: [
              {
                name: "Flax seeds",
                value: 100,
              },
            ],
            origin: "Ukraine",
            key: 45,
          },
          {
            title: "Poppy",
            ...nutritionFacts[30],
            ingredients: [
              {
                name: "Poppy",
                value: 100,
              },
            ],
            origin: "Turkey",
            key: 301,
          },
          {
            title: "Sesame seeds",
            ...nutritionFacts[18],
            ingredients: [
              {
                name: "Sesame seeds",
                value: "100",
              },
            ],
            origin: "India",
            key: 46,
          },
          {
            title: "Quinoa",
            ...nutritionFacts[24],
            ingredients: [
              {
                name: "White, black, red quinoa seeds",
                value: "",
              },
            ],
            origin: "Peru",
            key: 47,
          },
        ],
        mix: [
          {
            title: "Energy mix",
            ...nutritionFacts[1],
            origin: "Thailand, USA, Iran, Turkey, Vietnam",
            ingredients: [
              {
                name: "Pineapple",
                value: 20,
              },
              {
                name: "Papaya",
                value: 10,
              },
              {
                name: "Dried apricots",
                value: 10,
              },
              {
                name: "Almonds",
                value: 10,
              },
              {
                name: "Cranberries",
                value: 20,
              },
              {
                name: "Raisins",
                value: 20,
              },
              {
                name: "Cashew nuts",
                value: 10,
              },
            ],
            key: 49,
          },
          {
            title: "Tropical fruits",
            ...nutritionFacts[17],
            ingredients: [
              {
                name: "Pineapple",
                value: "20",
              },
              {
                name: "Papaya",
                value: "20",
              },
              {
                name: "Cranberries",
                value: "20",
              },
              {
                name: "Coconut cubes",
                value: "20",
              },
              {
                name: "Raisins",
                value: "20",
              },
            ],
            origin: "Thailand, Iran, USA, Netherlands",
            key: 50,
          },
          {
            title: "Tea Mix",
            ...nutritionFacts[22],
            ingredients: [
              {
                name: "Hazelnuts",
                value: "10",
              },
              {
                name: "Almonds",
                value: "15",
              },
              {
                name: "Raisins",
                value: "25",
              },
              {
                name: "Pineapple",
                value: "25",
              },
              {
                name: "Papaya",
                value: "25",
              },
            ],
            origin: "Turkey, USA, Chile, Thailand",
            key: 51,
          },
          {
            title: "4 Seeds",
            ...nutritionFacts[13],
            origin: "India, Ukraine, Moldova",
            ingredients: [
              {
                name: "Sunflower seeds",
                value: "25",
              },
              {
                name: "Flax seeds",
                value: "25",
              },
              {
                name: "Sesame seeds",
                value: 25,
              },
              {
                name: "Pumpkin seeds",
                value: 25,
              },
            ],
            key: 52,
          },

          {
            title: "Vegetable mix",
            ...nutritionFacts[15],
            ingredients: [
              {
                name: "Cassava",
                value: "",
              },
              {
                name: "Sweet potato",
                value: "",
              },
              {
                name: "Carrot",
                value: "",
              },
              {
                name: "Red beet",
                value: "",
              },
              {
                name: "Olive oil",
                value: "",
              },
              {
                name: "Natural plant extracts",
                value: "",
              },
            ],
            origin: "Moldova",
            key: 53,
          },
        ],
      },
    },
    benefits_animation: {
      firstLine: "A drop of health",
      secondLine: "and well-being.",
    },
    benefits: [
      {
        key: 1,
        title: "Healthy Mind",
        description:
          "Nuts and dried fruits contain Omega-3 and Omega-6 fatty acids, which are essential for brain function. Regular consumption can improve memory and focus, while also preventing cognitive decline.",
      },
      {
        key: 2,
        title: "Energy source",
        description:
          "Being rich in carbohydrates and proteins, dried fruits and nuts provide a quick energy boost, making them ideal as snacks before or after physical activities.",
      },
      {
        key: 3,
        title: "Heart health",
        description:
          'Nuts, especially raw ones, are an excellent source of unsaturated fats and fiber, which help reduce "bad" cholesterol (LDL) levels and maintain cardiovascular health.',
      },
      {
        key: 4,
        title: "Improved digestion",
        description:
          "Dried fruits, such as figs and prunes, are rich in fiber, contributing to healthy digestion and preventing constipation. They also support gut microbiota health.",
      },
      {
        key: 5,
        title: "Strengthening the immune system",
        description:
          "Nuts and dried fruits are rich in antioxidants (vitamin E, selenium), which help fight free radicals and strengthen the immune system.",
      },
      {
        key: 6,
        title: "Healthy Skin",
        description:
          "Vitamin E and other antioxidants in nuts contribute to skin health, protecting it from premature aging and the negative effects of UV radiation.",
      },
      {
        key: 7,
        title: "Weight Control",
        description:
          "Although high in calories, when consumed in moderation, nuts and dried fruits can help with weight control due to their protein and fiber content, which induce a feeling of satiety.",
      },
    ],
  },
  ru: {
    tags: {
      title: "Selik - широкий ассортимент фундука, орехов и сухофруктов",
      title2: "Смотрите продукты Selik на этой странице",
      description1:
        "Продукты Selik, ассортимент продуктов на нашей странице. Фундук, арахис, фисташки, кешью и сухофрукты. Откройте для себя продукты Selik",
      description2:
        "Мы нашли здесь фундук, арахис, кешью, сухофрукты и другие продукты Selik",
      url: "https://selik.md/ru",
    },
    search: {
      searchPlaceholder: "Найдите нужный товар",
      noresult: "Нет соответствующих результатов",
    },
    hero: {
      firstLine: "Ваш выбор",
      secondLine: "из орехов и сухофруктов",
    },
    contact: {
      h1: "свяжитесь с нами",
      field1: "ИМЯ",
      field2: "ЭЛЕКТРОННАЯ ПОЧТА",
      field3: "ТЕЛЕФОН",
      field4: "СООБЩЕНИЕ",
      dataProcess: "Данные обрабатываются в безопасной среде",
      button: "Отправить",
    },
    footer: {
      col1: "Контактные данные",
      col2: "Язык",
      address: "Улица Мунчешть 121, Кишинев, Молдова",
      rights: "Все права защищены - ",
      author: "Разработано и управляется",
    },
    nav: {
      origin: "ОРИГИНА",
      benefits: "ПРЕИМУЩЕСТВА",
      products: "ПРОДУКТЫ",
      contact: "КОНТАКТ",
    },
    origin: {
      title: "Вкусные смеси",
      subtitle: "энергия и настроение",
      description:
        "Вы можете выбрать отдельные продукты или нашу линейку смесей, которые являются источником энергии для спорта или перекусом для просмотра фильма.",
    },

    products: {
      category1: "Орехи",
      category2: "Сушеные фрукты",
      category3: "Семена",
      category4: "Микс",
      sectionTitle: "ПРОДУКТЫ",
      header: "Пищевая ценность",
      calories: "Калории",
      mass: "Чистый вес",
      weight: "Количество",
      fats: "Жиры",
      protein: "Белки",
      glucide: "Сахара",
      carbs: "Углеводы",
      fatAcids: "Насыщенные жирные кислоты",
      salt: "Соль",
      keepCondition:
        "Хранить в сухом и прохладном месте при температуре от +3°C до +18°C и влажности воздуха 70%",
      origin: "Страна происхождения",
      PRODUCTS: {
        nuts: [
          {
            title: "Фундук",
            ...nutritionFacts[0],
            origin: "Турция",
            ingredients: [
              {
                name: "Фундук",
                value: "100",
              },
            ],
            key: 54,
          },
          {
            title: "Очищенный арахис",
            ...nutritionFacts[3],
            ingredients: [
              {
                name: "Арахис",
                value: 100,
              },
            ],
            origin: "Индия",
            key: 55,
          },
          {
            title: "Соленый арахис",
            ...nutritionFacts[4],
            ingredients: [
              {
                name: "Арахис",
                value: 100,
              },
              {
                name: "Соль",
                value: "",
              },
            ],
            origin: "Индия",
            key: 57,
          },
          {
            title: "Фисташки",
            ...nutritionFacts[9],
            ingredients: [
              {
                name: "Фисташки",
                value: 100,
              },
            ],
            origin: "США",
            key: 58,
          },
          {
            title: "Миндаль",
            ...nutritionFacts[11],
            ingredients: [
              {
                name: "Миндаль",
                value: 100,
              },
            ],
            origin: "США",
            key: 59,
          },
          {
            title: "Фисташки",
            ...nutritionFacts[12],
            ingredients: [
              {
                name: "Фисташки",
                value: 100,
              },
            ],
            origin: "США",
            key: 60,
          },
          {
            title: "Орехи кешью",
            ...nutritionFacts[21],
            ingredients: [
              {
                name: "Орехи кешью",
                value: 100,
              },
            ],
            origin: "Вьетнам",
            key: 61,
          },
          {
            title: "Миндальные хлопья",
            ...nutritionFacts[19],
            ingredients: [
              {
                name: "Миндальные хлопья",
                value: "100",
              },
            ],
            origin: "США",
            key: 62,
          },
        ],
        fruits: [
          {
            title: "Манго",
            ...nutritionFacts[23],
            ingredients: [
              {
                name: "Манго",
                value: "",
              },
              {
                name: "Сахарный тростник",
                value: "",
              },
              {
                name: "Лимонная кислота",
                value: "",
              },
              {
                name: "Хлорид кальция",
                value: "",
              },
            ],
          },
          {
            title: "Ананас",
            ...nutritionFacts[2],
            ingredients: [
              {
                name: "Сушеный ананас",
                value: 100,
              },
            ],
            origin: "Таиланд",
            key: 63,
          },
          {
            title: "Финики",
            ...nutritionFacts[8],
            ingredients: [
              {
                name: "Финики",
                value: 100,
              },
            ],
            origin: "Тунис",
            key: 64,
          },
          {
            title: "Папайя",
            ...nutritionFacts[14],
            ingredients: [
              {
                name: "Папайя",
                value: "",
              },
              {
                name: "Сахарный тростник",
                value: "",
              },
              {
                name: "Лимонная кислота",
                value: "",
              },
              {
                name: "Хлорид кальция",
                value: "",
              },
            ],
            origin: "Таиланд",
            key: 65,
          },
          {
            title: "Изюм Султана",
            ...nutritionFacts[16],
            ingredients: [
              {
                name: "Сушеный изюм Султана",
                value: "100",
              },
            ],
            origin: "Иран",
            key: 66,
          },
          {
            title: "Кокосовые хлопья",
            ...nutritionFacts[7],
            ingredients: [
              {
                name: "Кокос",
                value: 100,
              },
            ],
            origin: "Шри-Ланка",
            key: 67,
          },
          {
            title: "Имбирь",
            ...nutritionFacts[20],
            ingredients: [
              {
                name: "Имбирь",
                value: "",
              },
              {
                name: "Сахарный тростник",
                value: "",
              },
              {
                name: "Лимонная кислота",
                value: "",
              },
              {
                name: "Глазурь",
                value: "",
              },
            ],
            origin: "Таиланд",
            key: 68,
          },
          {
            title: "Золотой изюм",
            ...nutritionFacts[26],
            ingredients: [
              {
                name: "Сушеный золотой изюм",
                value: "",
              },
            ],
            origin: "Иран",
            key: 69,
          },
          {
            title: "Клюква",
            ...nutritionFacts[27],
            ingredients: [
              {
                name: "Сушёная клюква",
                value: "",
              },
              {
                name: "Тростниковый сахар",
                value: "",
              },
            ],
            origin: "США",
            key: 202,
          },
          {
            title: "Сушёные абрикосы",
            ...nutritionFacts[28],
            ingredients: [
              {
                name: "Сушёные абрикосы",
                value: "100",
              },
            ],
            origin: "Турция",
            key: 206,
          },
          {
            "title": "Инжир",
            ...nutritionFacts[29],
            "ingredients": [
              {
                "name": "Сушеный инжир",
                "value": 100
              }
            ],
            "origin": "Турция",
            "key": 312
          }          
        ],
        seeds: [
          {
            title: "Семена чиа",
            ...nutritionFacts[6],
            ingredients: [
              {
                name: "Семена чиа",
                value: 100,
              },
            ],
            origin: "Аргентина",
            key: 70,
          },
          {
            title: "Семена льна",
            ...nutritionFacts[10],
            ingredients: [
              {
                name: "Семена льна",
                value: 100,
              },
            ],
            origin: "Украина",
            key: 71,
          },
          {
            title: "Мак",
            ...nutritionFacts[30],
            ingredients: [
              {
                name: "Мак",
                value: 100,
              },
            ],
            origin: "Турция",
            key: 310,
          },
          {
            title: "Киноа",
            ...nutritionFacts[24],
            ingredients: [
              {
                name: "Семена киноа белые, черные, красные",
                value: "",
              },
            ],
            origin: "Перу",
            key: 72,
          },
          {
            title: "Семена кунжута",
            ...nutritionFacts[18],
            ingredients: [
              {
                name: "Семена кунжута",
                value: "100",
              },
            ],
            origin: "Индия",
            key: 74,
          },
        ],
        mix: [
          {
            title: "Энергетический микс",
            ...nutritionFacts[1],
            origin: "Таиланд, США, Иран, Турция, Вьетнам",
            ingredients: [
              {
                name: "Ананас",
                value: 20,
              },
              {
                name: "Папайя",
                value: 10,
              },
              {
                name: "Сушеный абрикос",
                value: 10,
              },
              {
                name: "Миндаль",
                value: 10,
              },
              {
                name: "Клюква",
                value: 20,
              },
              {
                name: "Изюм",
                value: 20,
              },
              {
                name: "Кешью",
                value: 10,
              },
            ],
            key: 75,
          },
          {
            title: "Тропические фрукты",
            ...nutritionFacts[17],
            ingredients: [
              {
                name: "Ананас",
                value: "20",
              },
              {
                name: "Папайя",
                value: "20",
              },
              {
                name: "Клюква",
                value: "20",
              },
              {
                name: "Кубики кокоса",
                value: "20",
              },
              {
                name: "Изюм",
                value: "20",
              },
            ],
            origin: "Таиланд, Иран, США, Нидерланды",
            key: 76,
          },
          {
            title: "Чайная смесь",
            ...nutritionFacts[22],
            ingredients: [
              {
                name: "Лесные орехи",
                value: "10",
              },
              {
                name: "Миндаль",
                value: "15",
              },
              {
                name: "Изюм",
                value: "25",
              },
              {
                name: "Ананас",
                value: "25",
              },
              {
                name: "Папайя",
                value: "25",
              },
            ],
            origin: "Турция, США, Чили, Таиланд",
            key: 77,
          },
          {
            title: "4 Семени",
            ...nutritionFacts[13],
            origin: "Индия, Украина, Молдова",
            ingredients: [
              {
                name: "Семена подсолнечника",
                value: "25",
              },
              {
                name: "Семена льна",
                value: "25",
              },
              {
                name: "Семена кунжута",
                value: 25,
              },
              {
                name: "Тыквенные семечки",
                value: 25,
              },
            ],
            key: 78,
          },

          {
            title: "Овощной микс",
            ...nutritionFacts[15],
            ingredients: [
              {
                name: "Кассава",
                value: "",
              },
              {
                name: "Батат",
                value: "",
              },
              {
                name: "Морковь",
                value: "",
              },
              {
                name: "Свекла",
                value: "",
              },
              {
                name: "Оливковое масло",
                value: "",
              },
              {
                name: "Натуральные растительные экстракты",
                value: "",
              },
            ],
            origin: "Молдова",
            key: 79,
          },
        ],
      },
    },

    benefits_animation: {
      firstLine: "Капля здоровья",
      secondLine: "и энергии",
    },
    benefits: [
      {
        key: 1,
        title: "Здоровый разум",
        description:
          "Орехи и сушеные фрукты содержат жирные кислоты Омега-3 и Омега-6, которые необходимы для функционирования мозга. Регулярное потребление может улучшить память и концентрацию, предотвращая при этом когнитивный спад.",
      },
      {
        key: 2,
        title: "Источник энергии",
        description:
          "Богатые углеводами и белками, сушеные фрукты и орехи обеспечивают быструю энергетическую подпитку, что делает их идеальными закусками перед или после физических нагрузок.",
      },
      {
        key: 3,
        title: "Здоровье сердца",
        description:
          'Орехи, особенно сырые, являются отличным источником ненасыщенных жиров и клетчатки, которые помогают снизить уровень "плохого" холестерина (ЛПНП) и поддерживать здоровье сердечно-сосудистой системы.',
      },
      {
        key: 4,
        title: "Улучшение пищеварения",
        description:
          "Сушеные фрукты, такие как инжир и сливы, богаты клетчаткой, способствуя здоровому пищеварению и предотвращая запоры. Они также поддерживают здоровье микробиоты кишечника.",
      },
      {
        key: 5,
        title: "Укрепление иммунной системы",
        description:
          "Орехи и сушеные фрукты богаты антиоксидантами (витамин E, селен), которые помогают бороться со свободными радикалами и укреплять иммунную систему.",
      },
      {
        key: 6,
        title: "Здоровая кожа",
        description:
          "Витамин E и другие антиоксиданты в орехах способствуют здоровью кожи, защищая ее от преждевременного старения и негативных эффектов УФ-излучения.",
      },
      {
        key: 7,
        title: "Контроль веса",
        description:
          "Хотя они высококалорийны, при умеренном потреблении орехи и сушеные фрукты могут помочь в контроле веса благодаря содержанию белка и клетчатки, которые вызывают чувство сытости.",
      },
    ],
  },
};
