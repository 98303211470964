import Benefit from "./Benefit";
import {text} from '../text/text';
import {useState, useEffect } from 'react';
import { useParams } from "react-router-dom";


const BenefitsDesktop = () => {
  const [language, setLanguage] = useState("ro");

  let { lang } = useParams();
  if (!lang) lang = "ro";
  let content = text[language];

  useEffect(() => {
    if (!lang) setLanguage("ro");
    else setLanguage(lang);
    
  }, [lang]);

  return (
    <div className="grid grid-cols-[1fr_1fr] h-fit">
      <div className="h-fit">
        <div style={{backgroundImage: `url(mental-health.jpg)`}} className="h-screen w-full bg-contain bg-fixed"></div>
        <div style={{backgroundImage: `url(energy.jpg)`}} className="h-screen w-full bg-contain bg-fixed"></div>
        <div style={{backgroundImage: `url(heart.jpg)`}} className="h-screen w-full bg-contain bg-fixed"></div>
        <div style={{backgroundImage: `url(nuts.jpg)`}} className="h-screen w-full bg-contain bg-fixed"></div>
        <div style={{backgroundImage: `url(sistem-imunitar.jpg)`}} className="h-screen w-full bg-contain bg-fixed"></div>
        <div style={{backgroundImage: `url(skin.jpg)`}} className="h-screen w-full bg-contain bg-fixed"></div>
        <div style={{backgroundImage: `url(cantar.jpg)`}} className="h-screen w-full bg-contain bg-fixed"></div>

      </div>
      <div className="h-[700vh] flex flex-col bg-teal-500">
        {content.benefits.map((benefit) => (
          <Benefit
            key={benefit.key}
            title={benefit.title}
            description={benefit.description}
          />
        ))}
      </div>
    </div>
  );
};




export default BenefitsDesktop;
