import {
  CommandDialog,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from "./ui/command";
import { useParams } from "react-router-dom";
import { text } from "../text/text";
import { X, Search } from "lucide-react";
import React, { useEffect, useId, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { cn } from "../lib/utils";
import { Button } from "./ui/button";

const SearchbarMobile = () => {
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState("ro");

  let { lang } = useParams();
  if (!lang) lang = "ro";
  let content = text[language];

  useEffect(() => {
    if (!lang) setLanguage("ro");
    else setLanguage(lang);
  }, [lang]);
  const [active, setActive] = useState(null);
  const ref = useRef(null);
  const id = useId();

  useEffect(() => {
    if (active && typeof active === "object") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [active]);

  useOnClickOutside(ref, () => setActive(null));

  return (
    <>
      {open ? (
        <div>
          <CommandDialog
            open={open}
            onOpenChange={setOpen}
            className={cn(
              "mt-[calc(80px-22px)] rounded-lg border shadow-md h-11"
            )}
          >
            <CommandInput placeholder={`${content.search.searchPlaceholder}`} />
            <CommandList>
              <CommandEmpty>{content.search.noresults}</CommandEmpty>
              <CommandGroup heading="Produse">
                <>
                  <AnimatePresence>
                    {active && typeof active === "object" && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black/20 h-full w-full z-10"
                      />
                    )}
                  </AnimatePresence>
                  <AnimatePresence>
                    {active && typeof active === "object" ? (
                      <div className="fixed inset-0  grid place-items-center z-[100]">
                        <motion.button
                          key={`button-${active.title}-${id}`}
                          layout
                          initial={{
                            opacity: 0,
                          }}
                          animate={{
                            opacity: 1,
                          }}
                          exit={{
                            opacity: 0,
                            transition: {
                              duration: 0.05,
                            },
                          }}
                          className="flex absolute top-2 right-2 lg:hidden items-center justify-center bg-white rounded-full h-6 w-6"
                          onClick={() => setActive(null)}
                        >
                          <X />
                        </motion.button>
                        <motion.div
                          layoutId={`card-${active.title}-${id}`}
                          ref={ref}
                          className="w-full max-w-[500px]  h-full md:h-fit md:max-h-[90%]  flex flex-col bg-white dark:bg-neutral-900 sm:rounded-3xl overflow-hidden"
                        >
                          <motion.div layoutId={`image-${active.title}-${id}`}>
                            <img
                              width={200}
                              height={200}
                              src={active.imgSrc}
                              alt={active.title}
                              className="w-fit mx-auto h-80 lg:h-80 sm:rounded-tr-lg sm:rounded-tl-lg object-cover object-top"
                            />
                          </motion.div>

                          <div>
                            <div className="flex justify-between items-start p-4">
                              <div className="">
                                <motion.h3
                                  layoutId={`title-${active.title}-${id}`}
                                  className="font-bold text-neutral-700 dark:text-neutral-200"
                                >
                                  {active.title}
                                </motion.h3>
                                <motion.p
                                  layoutId={`description-${active.description}-${id}`}
                                  className="text-neutral-600 dark:text-neutral-400"
                                >
                                  {active.description}
                                  {content.products.weight} {active.weight}
                                  {"g"}
                                </motion.p>
                              </div>
                            </div>
                            <div className="pt-4 relative px-4">
                              <motion.div
                                layout
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="text-neutral-600 text-xs md:text-sm lg:text-base h-40 md:h-fit pb-10 flex flex-col items-start gap-4 overflow-auto dark:text-neutral-400 [mask:linear-gradient(to_bottom,white,white,transparent)] [scrollbar-width:none] [-ms-overflow-style:none] [-webkit-overflow-scrolling:touch]"
                              >
                                {typeof active.content === "function"
                                  ? active.content()
                                  : null}
                              </motion.div>
                            </div>
                          </div>
                        </motion.div>
                      </div>
                    ) : null}
                  </AnimatePresence>

                  <ul className="max-w-2xl mx-auto w-full gap-4">
                  {content.products.PRODUCTS.nuts.map((card, index) => (
                  <CommandItem key={`card-${card.key}`}>
                    <motion.div
                      layoutId={`card-${card.title}-${id}`}
                      onClick={() => setActive(card)}
                      className="p-4 flex flex-col md:flex-row justify-between items-center hover:bg-neutral-50 dark:hover:bg-neutral-800 rounded-xl cursor-pointer"
                    >
                      <div className="hidden">
                        {card.ingredients.map((ingredient, i) => (
                          <p key={`${i}`} className="hidden">
                            {ingredient.name}
                          </p>
                        ))}
                      </div>
                      <div className="flex gap-4 flex-col md:flex-row ">
                        <motion.div layoutId={`image-${card.title}-${id}`}>
                          <img
                            priority="true"
                            width={100}
                            height={100}
                            src={card.imgSrc}
                            alt={card.title}
                            className="h-40 w-40 md:h-14 md:w-14 rounded-lg object-cover object-top"
                          />
                        </motion.div>
                        <div className="">
                          <motion.h3
                            layoutId={`title-${card.title}-${id}`}
                            className="font-medium text-neutral-800 dark:text-neutral-200 text-center md:text-left"
                          >
                            {card.title}
                          </motion.h3>
                        </div>
                      </div>
                    </motion.div>
                  </CommandItem>
                ))}

                {content.products.PRODUCTS.fruits.map((card, index) => (
                  <CommandItem key={`card-${card.key}`}>
                    <motion.div
                      layoutId={`card-${card.title}-${id}`}
                      onClick={() => setActive(card)}
                      className="p-4 flex flex-col md:flex-row justify-between items-center hover:bg-neutral-50 dark:hover:bg-neutral-800 rounded-xl cursor-pointer"
                    >
                      <div className="hidden">
                        {card.ingredients.map((ingredient, i) => (
                          <p key={`${i}`} className="hidden">
                            {ingredient.name}
                          </p>
                        ))}
                      </div>
                      <div className="flex gap-4 flex-col md:flex-row ">
                        <motion.div layoutId={`image-${card.title}-${id}`}>
                          <img
                            priority="true"
                            width={100}
                            height={100}
                            src={card.imgSrc}
                            alt={card.title}
                            className="h-40 w-40 md:h-14 md:w-14 rounded-lg object-cover object-top"
                          />
                        </motion.div>
                        <div className="">
                          <motion.h3
                            layoutId={`title-${card.title}-${id}`}
                            className="font-medium text-neutral-800 dark:text-neutral-200 text-center md:text-left"
                          >
                            {card.title}
                          </motion.h3>
                        </div>
                      </div>
                    </motion.div>
                  </CommandItem>
                ))}

                {content.products.PRODUCTS.seeds.map((card, index) => (
                  <CommandItem key={`card-${card.key}`}>
                    <motion.div
                      layoutId={`card-${card.title}-${id}`}
                      onClick={() => setActive(card)}
                      className="p-4 flex flex-col md:flex-row justify-between items-center hover:bg-neutral-50 dark:hover:bg-neutral-800 rounded-xl cursor-pointer"
                    >
                      <div className="hidden">
                        {card.ingredients.map((ingredient, i) => (
                          <p key={`${i}`} className="hidden">
                            {ingredient.name}
                          </p>
                        ))}
                      </div>
                      <div className="flex gap-4 flex-col md:flex-row ">
                        <motion.div layoutId={`image-${card.title}-${id}`}>
                          <img
                            priority="true"
                            width={100}
                            height={100}
                            src={card.imgSrc}
                            alt={card.title}
                            className="h-40 w-40 md:h-14 md:w-14 rounded-lg object-cover object-top"
                          />
                        </motion.div>
                        <div className="">
                          <motion.h3
                            layoutId={`title-${card.title}-${id}`}
                            className="font-medium text-neutral-800 dark:text-neutral-200 text-center md:text-left"
                          >
                            {card.title}
                          </motion.h3>
                        </div>
                      </div>
                    </motion.div>
                  </CommandItem>
                ))}

                {content.products.PRODUCTS.mix.map((card, index) => (
                  <CommandItem key={`card-${card.key}`}>
                    <motion.div
                      layoutId={`card-${card.title}-${id}`}
                      onClick={() => setActive(card)}
                      className="p-4 flex flex-col md:flex-row justify-between items-center hover:bg-neutral-50 dark:hover:bg-neutral-800 rounded-xl cursor-pointer"
                    >
                      <div className="hidden">
                        {card.ingredients.map((ingredient, i) => (
                          <p key={`${i}`} className="hidden">
                            {ingredient.name}
                          </p>
                        ))}
                      </div>
                      <div className="flex gap-4 flex-col md:flex-row ">
                        <motion.div layoutId={`image-${card.title}-${id}`}>
                          <img
                            priority="true"
                            width={100}
                            height={100}
                            src={card.imgSrc}
                            alt={card.title}
                            className="h-40 w-40 md:h-14 md:w-14 rounded-lg object-cover object-top"
                          />
                        </motion.div>
                        <div className="">
                          <motion.h3
                            layoutId={`title-${card.title}-${id}`}
                            className="font-medium text-neutral-800 dark:text-neutral-200 text-center md:text-left"
                          >
                            {card.title}
                          </motion.h3>
                        </div>
                      </div>
                    </motion.div>
                  </CommandItem>
                ))}
                  </ul>
                </>
              </CommandGroup>
            </CommandList>
          </CommandDialog>
        </div>
      ) : (
        <div className="flex justify-end">
          <Button
            variant="secondary"
            className="w-fit "
            onClick={() => setOpen(true)}
          >
            <Search />
          </Button>
        </div>
      )}
    </>
  );
};

export default SearchbarMobile;
